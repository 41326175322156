<script lang="ts" setup>
import type { ImageDisplayIn, ImagePosition } from '~/models';

import MadeWithLove from '~/components/common/MadeWithLove.vue';
import Carousel from '~/components/home/Carousel.vue';
import OpeningHours from '~/components/home/OpeningHours.vue';
import FoodinnIcon from '~/components/icons/FoodinnIcon.vue';

const { isMobile } = useDevice();
const companyStore = useCompanyStore();
const preferenceStore = usePreferenceStore();

useHead({
  titleTemplate: '%s'
});

useSeoMeta({
  title: preferenceStore.preference.general.seo?.title,
  googleSiteVerification: preferenceStore.preference.general.seo?.googleSiteVerificationKey
});

const { data: companies } = await useAsyncData('company-list', async () => {
  const response = await companyStore.list();
  return (response ?? []).filter((company) => company.showInHome);
});

const getImagesUrlByPosition = (position: ImagePosition, displayIn: ImageDisplayIn = 'All'): string[] => {
  if (!preferenceStore.preference.template.mainPageImages) {
    return [];
  }

  const displays: ImageDisplayIn[] = ['All', displayIn];

  return preferenceStore.preference.template.mainPageImages
    .filter((imageUrl) => {
      return (
        imageUrl.position === position &&
        displays.includes(imageUrl.displayIn)
      );
    })
    .sort(({ ranking: a }, { ranking: b }) => a > b ? 1 : a < b ? -1 : 0)
    .map((imageUrl) => imageUrl.url);
};

const slideImageUrls = computed<string[]>(() => {
  return getImagesUrlByPosition('slide');
});

const carouselImageUrls = computed<string[]>(() => {
  return getImagesUrlByPosition('horizontal slide');
});

const homeImageUrls = computed<string[]>(() => {
  return getImagesUrlByPosition('footer', isMobile ? 'Mobile' : 'Desktop');
});

const currentSlideImageUrl = ref<string>(slideImageUrls.value[0] ?? '');

const urlMapRedirect = computed<string>(() => {
  const latitude = preferenceStore.preference.company.latitude;
  const longitude = preferenceStore.preference.company.longitude;

  return `https://www.google.com.br/maps/@${latitude},${longitude},17z`;
});

const mapImage = computed<string>(() => {
  const URL_DEFAULT = '/images/foodinn-map-location.webp';

  return preferenceStore.preference.template.footerMapImageUrl || URL_DEFAULT;
});

const infinitSlide = (nextIndex: number) => {
  setTimeout(() => {
    currentSlideImageUrl.value = slideImageUrls.value[nextIndex];
    nextIndex++;

    if (nextIndex >= slideImageUrls.value.length) {
      nextIndex = 0;
    }

    infinitSlide(nextIndex);
  }, 6000);
};

const startSlide = () => {
  if (slideImageUrls.value.length <= 0) {
    return;
  }

  const nextIndex = 1;

  infinitSlide(nextIndex);
};

onMounted(startSlide);
</script>

<template>
  <div class="home">
    <div class="home__content">
      <!-- SEO Headings -->
      <h1 v-show="false">Home</h1>

      <img
        v-if="currentSlideImageUrl"
        :src="currentSlideImageUrl"
        alt="slide-image"
        title="slide-image"
        class="home__slide"
        data-test="home-slide"
      >

      <UContainer class="relative h-screen h-svh flex justify-center items-center">
        <template v-if="companies && companies.length > 0">
          <div class="flex flex-wrap gap-2 justify-center items-center">
            <UButton
              v-for="company of companies"
              :key="company.uuid"
              variant="solid"
              class="bg-company-1 w-80 py-4 font-semibold text-xl justify-center"
              :to="company.websiteUrl"
            >
              <UIcon
                name="i-ic-outline-menu-book"
                class="text-3xl"
              />
              <span data-test="company-name">{{ company.name }}</span>
            </UButton>
          </div>
        </template>

        <ULink
          v-else-if="preferenceStore.preference.template.menuButtonImageUrl"
          :to="{ name: 'menu' }"
        >
          <img
            :src="preferenceStore.preference.template.menuButtonImageUrl"
            alt="menu-button"
            title="menu-button"
            class="home__button-image"
            data-test="button-image"
          >
        </ULink>

        <UButton
          v-else
          variant="solid"
          class="bg-company-1 w-80 py-4 font-semibold text-xl justify-center"
          data-test="browse-menu"
          :to="{ name: 'menu' }"
        >
          <UIcon
            name="i-ic-outline-menu-book"
            class="text-3xl"
          />
          <span>Browse Menu</span>
        </UButton>

        <UContainer
          v-if="carouselImageUrls.length > 0"
          class="absolute"
          style="bottom: 1rem;"
        >
          <Carousel :image-urls="carouselImageUrls" />
        </UContainer>
      </UContainer>
    </div>

    <UContainer
      v-if="homeImageUrls.length > 0"
      class="flex flex-col gap-2 px-0"
    >
      <img
        v-for="(imageUrl, index) of homeImageUrls"
        :key="index"
        :src="imageUrl"
        :alt="`image-${index}`"
        :title="`image-${index}`"
        data-test="home-image"
      >
    </UContainer>

    <div class="bg-footer">
      <UContainer class="flex flex-col lg:flex-row lg:items-center">
        <OpeningHours
          class="flex-1"
          :collection-times="preferenceStore.preference.openingTimes"
          :delivery-times="preferenceStore.preference.deliveryTimes"
        />
        <a
          :href="urlMapRedirect"
          target="_blank"
          title="Google Maps"
          class="self-center max-w-xl"
        >
          <img
            :src="mapImage"
            alt="map"
            title="map"
            data-test="map-image"
            class="rounded my-4"
          >
        </a>
      </UContainer>

      <UContainer class="home__footer">
        <template v-if="isMobile">
          <FoodinnIcon data-test="foodinn-icon" />
          <MadeWithLove data-test="made-with-love" />
        </template>

        <template v-else>
          <span>
            Powered by Foodinn
          </span>
          <span>
            Copyright &copy; 2016-2022. All Rights Reserved.
          </span>
        </template>
      </UContainer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
body.mobile {
  .home {

    &__info,
    &__footer {
      padding: 0 1rem;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: normal;

      &>a {
        margin: 1rem 0 0 0;
      }
    }

    &__footer {
      flex-direction: column;
      height: 5.5rem;
      justify-items: center;
      align-items: center;
      padding: 1rem;

      &>span {
        content: '';
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -1.1rem;
          left: 1.2rem;
          width: 1rem;
          height: 0.5rem;
          border-top: 2px;
          border-left: 2px;
          border-right: 0;
          border-bottom: 0;
          border-style: solid;
          border-color: var(--color-border);
        }

        &::after {
          content: '';
          position: absolute;
          top: -1.1rem;
          right: 1.2rem;
          width: 1rem;
          height: 0.5rem;
          border-top: 2px;
          border-left: 0;
          border-right: 2px;
          border-bottom: 0;
          border-style: solid;
          border-color: var(--color-border);
        }
      }
    }
  }
}

.home {
  color: var(--color-border);

  &__content {
    width: 100%;
    background:
      linear-gradient(180deg, #646464 0%, #646464 100%),
      url('/images/people-table.jpg');
    background-blend-mode: multiply;
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: center;
    align-content: center;
    text-align: center;
    position: relative;

    & .home {
      &__slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      &__button-image {
        height: 5rem;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr 600px;
    align-items: center;

    &>a {
      margin: 1rem 0 0 0;
      border-radius: 8px;
      margin: 1rem 0 0 1rem;
      overflow: hidden;

      &>img {
        width: 100%;
        object-fit: cover;
        object-position: center center;
        margin-bottom: -0.5rem;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding-block: 1rem;
  }
}
</style>
